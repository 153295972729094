/* Auth.css */
.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #E4E4E4;
    font-family: 'Inter', sans-serif;
  }
  
  .auth-container h2 {
    color: #276D8B;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .auth-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .auth-form input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .auth-button {
    background-color: #276D8B;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .auth-button:hover {
    background-color: #1e5a73;
  }
  
  .auth-container p {
    margin-top: 20px;
  }
  
  .auth-container a {
    color: #276D8B;
    text-decoration: none;
    font-weight: bold;
  }
  
  .auth-container a:hover {
    text-decoration: underline;
  }
  
  .error-message {
    color: #f78d8d;
    margin-top: 10px;
  }

  .society-code-input {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

  }
  
  .code-input {
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 2px;
  }
  
  .code-input:focus {
    border-color: #276D8B;
    outline: none;
  }

  .society-name {
    color: #888;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
  /* Add these styles to your Layout.css file */

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-overlay .auth-container {
    min-height: auto;
    background-color: transparent;
  }
  
  .join-society {
    color: #276D8B;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 5px;
  }