.videos-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .videos-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .videos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
  }
  
  .video-card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .video-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    margin-bottom: 1rem;
  }
  
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video-description {
    margin-bottom: 1rem;
  }
  
  .button {
    background-color: #276D8B;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0051a8;
  }
  
  .button-icon {
    margin-right: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .videos-grid {
      grid-template-columns: 1fr;
    }
  }