.speak-to-founders-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .page-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .intro-text {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    color: #666;
  }
  
  .founders-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .founder-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
  }
  
  .founder-card h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .founder-bio {
    margin-bottom: 1rem;
    color: #444;
  }
  
  .founder-contact {
    display: flex;
    flex-direction: column;
  }
  
  .contact-link {
    display: flex;
    align-items: center;
    color: #276D8B;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .contact-link svg {
    margin-right: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .founders-grid {
      grid-template-columns: 1fr;
    }
  }

.founders-title {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #111;
  }
  
  .chat-intro {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    color: #666;
  }
  
  .chat-area {
    max-width: 600px;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #ccc;
    overflow: hidden;
  }
  
  .chat-messages {
    height: 400px;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column-reverse;
  }
  
  .chat-message {
    max-width: 70%;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    word-wrap: break-word;
  }
  
  .chat-message.user {
    align-self: flex-end;
    background-color: #276D8B;
    color: white;
  }
  
  .chat-message.support {
    align-self: flex-start;
    background-color: #f0f0f0;
    color: black;
  }
  
  .chat-message p {
    margin: 0;
  }
  
  .chat-message small {
    display: block;
    font-size: 0.8rem;
    margin-top: 0.25rem;
    opacity: 0.7;
  }
  
  .chat-input {
    display: flex;
    padding: 1rem;
    background-color: #f9f9f9;
  }
  
  .chat-input input {
    flex-grow: 1;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 0.5rem;
  }
  
  .chat-input button {
    background-color: #276D8B;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .chat-input button:hover {
    background-color: #0051a8;
  }

  .name-bruh {
    color: #111;
  }