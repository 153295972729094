.landing-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
}

.header-panel {
  background-color: white;
  border-bottom: 1px solid #eaeaea;
  padding: 1rem 2rem;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a365d;
  margin: 0;
}

.login-link {
  color: #4a5568;
  text-decoration: none;
  font-size: 0.875rem;
}

.login-link:hover {
  text-decoration: underline;
}

.landing-main {
  flex-grow: 1;
  padding: 4rem 2rem;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.main-heading {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  color: #4a5568;
  margin-bottom: 4rem;
  line-height: 1.2;
}

.features-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 4rem;
}

.feature-box {
  width: 100%;
  padding: 2rem;
  background: white;
  border: 1px solid #eaeaea;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.feature-content {
  flex-grow: 1;
}

.feature-box h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 0.5rem;
}

.feature-box p {
  color: #718096;
  margin: 0;
  line-height: 1.5;
}

.primary-button,
.secondary-button {
  padding: 0.75rem 2rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: all 0.2s ease;
  width: fit-content;
  align-self: flex-start;
}

.primary-button {
  background: #000;
  color: white;
  border: none;
}

.primary-button:hover {
  background: #2d3748;
}

.secondary-button {
  background: #10b981;
  color: white;
}

.secondary-button:hover {
  background: #059669;
}

.logo-slider {
  overflow: hidden;
  padding: 2rem 0;
  background: white;
  margin: 4rem 0;
  width: 100%;
}

.logo-slide-track {
  display: flex;
  animation: scroll 30s linear infinite;
  width: calc(250px * 10);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 5));
  }
}

.logo-slide {
  flex: 0 0 250px;
  padding: 0 2rem;
}

.firm-logo {
  height: 45px;
  width: auto;
  object-fit: contain;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.firm-logo:hover {
  opacity: 1;
}

.faq-section {
  margin: 4rem 0;
}

.faq-section h3 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.faq-item {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 2rem;
}

.faq-item h4 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #2d3748;
}

.faq-item p {
  color: #718096;
  line-height: 1.6;
}

.footer {
  margin-top: auto;
  border-top: 1px solid #eaeaea;
  padding: 2rem;
  background: white;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkedin-link {
  color: #2d3748;
  text-decoration: none;
}

.linkedin-link:hover {
  text-decoration: underline;
}