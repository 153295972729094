.firm-dashboard-firmdash {
    background-color: #E4E4E4;
    min-height: 100vh;
    padding: 20px;
    font-family: "Inter", sans-serif;
}

.toolbar-firmdash {
    background-color: #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.firm-name-firmdash {
    font-size: 24px;
    font-weight: bold;
    color: #276D8B;
    flex: 1;
}

.tab-toggle-firmdash {
    display: flex;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    margin: 0 20px;
}

.tab-button-firmdash {
    flex: 1;
    background: none;
    border: none;
    padding: 10px 0;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
    color: #333;
    text-align: center;
    white-space: nowrap;
}

.tab-button-firmdash.active {
    background-color: #276D8B;
    color: white;
}

.save-button-container-firmdash {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.save-button-firmdash {
    background-color: #276D8B;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.save-button-firmdash:hover {
    background-color: #1c4d62;
}

.dashboard-container-firmdash {
    background-color: transparent;
    border-radius: 5px;
}

.dashboard-content-firmdash {
    display: flex;
    gap: 20px;
}

.left-column-firmdash {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.right-column-firmdash {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.firm-details-firmdash {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.firm-details-firmdash h2 {
    color: #276D8B;
    margin-bottom: 10px;
}

.score-display {
    background-color: #276D8B;
    border-radius: 10px;
    padding: 24px;
    color: white;
    display: flex;
    flex-direction: column;
}

.score-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 50px;
}

.score-title {
    font-size: 72px;
    font-weight: bold;
    margin: 0;
    line-height: 1;
}

.score-subtitle {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 8px;
}

.score-content {
    margin-top: auto;
}

.score-sections {
    display: flex;
    justify-content: space-between;
}

.score-section {
    flex: 1;
    text-align: center;
    padding: 0 15px;
}

.score-section:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.section-score {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 8px;
}

.section-title {
    font-size: 14px;
    color: rgb(194, 194, 194) !important;
    margin-bottom: 12px;
}

.section-justification {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 1.4;
}

.error-banner {
    background-color: #ff6b6b;
    color: white;
    padding: 10px 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    text-align: center;
}

@media (max-width: 1024px) {
    .toolbar-firmdash {
        flex-wrap: wrap;
    }

    .firm-name-firmdash {
        flex-basis: 100%;
        margin-bottom: 10px;
        text-align: center;
    }

    .tab-toggle-firmdash {
        order: 3;
        margin: 10px 0;
        max-width: none;
    }

    .save-button-container-firmdash {
        flex-basis: 100%;
        justify-content: center;
    }

    .dashboard-content-firmdash {
        flex-direction: column;
    }

    .left-column-firmdash,
    .right-column-firmdash {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .firm-dashboard-firmdash {
        padding: 10px;
    }

    .toolbar-firmdash {
        flex-direction: column;
        align-items: stretch;
    }

    .tab-toggle-firmdash {
        flex-direction: column;
        border-radius: 5px;
    }

    .tab-button-firmdash {
        padding: 10px 0;
    }

    .save-button-firmdash {
        width: 100%;
    }

    .score-sections {
        flex-direction: column;
    }

    .score-section {
        margin-bottom: 20px;
        padding: 0;
    }

    .score-section:not(:last-child) {
        border-right: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: 20px;
    }
}

@media (max-width: 480px) {
    .firm-dashboard-firmdash {
        padding: 5px;
    }

    .toolbar-firmdash {
        padding: 10px;
    }

    .firm-name-firmdash {
        font-size: 20px;
    }

    .score-title {
        font-size: 48px;
    }

    .score-subtitle {
        font-size: 16px;
    }

    .section-score {
        font-size: 28px;
    }

    .section-title {
        font-size: 12px;
    }

    .section-justification {
        font-size: 10px;
    }
}