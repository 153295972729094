.survey-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.survey-content {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.survey-content h2 {
  color: #276D8B;
  margin-bottom: 20px;
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.option input[type="radio"] {
  display: none;
}

.option label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.option label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #276D8B;
  border-radius: 50%;
}

.option input[type="radio"]:checked + label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: #276D8B;
  border-radius: 50%;
}

.option.selected {
  background-color: #276D8B;
}

.option.selected label {
  color: white;
}

.option.selected label:before {
  border-color: white;
}

.option.selected input[type="radio"]:checked + label:after {
  background-color: white;
}

.other-input {
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
}

.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #276D8B;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #1c4d62;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}