/* ApplicationInput.css */

/* Container layout */
.application-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Title card styling */
.title-card {
    background-color: #276D8B;
    color: white;
    padding: 30px;
    border-radius: 10px;
    margin: 10px 30px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.title-card.expanded {
    flex-grow: 1;
}

.title-card h3 {
    margin: 0 0 10px 0;
    font-size: 1.2em;
}

/* Dropdown styling */
.dropdown-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.dropdown-container > div {
    width: 48%;
}

/* Text content layout */
.text-content {
    flex-grow: 1;
    background-color: rgba(39, 109, 139, 0.6);
    padding: 25px;
    border-radius: 10px;
    color: white;
    overflow-y: auto;
    margin: 10px 30px;
    display: flex;
    flex-direction: column;
}

/* Editable content container */
.editable-content-container {
    position: relative;
    width: 100%;
    height: 100%;
}

/* Editable text area */
.editable-content {
    width: 100%;
    height: 100%;
    min-height: 300px;
    padding-bottom: 30px;
    resize: none;
    background-color: transparent;
    color: inherit;
    font: inherit;
    border: none;
    outline: none;
    overflow-y: auto;
    white-space: pre-wrap;
}

/* Word count display */
.word-count {
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: white;
    font-size: 0.9rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 5px;
    pointer-events: none;
    z-index: 10;
}

/* Additional information row */
.additional-info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

/* Import Draft button styling */
.import-draft-btn {
    background-color: #000000;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: auto;
}

.import-draft-btn:hover {
    background-color: #1e5a73;
}

/* Input fields for additional information */
.input-field {
    margin-bottom: 15px;
}

.input-field label {
    display: block;
    margin-bottom: 5px;
    color: white;
}

/* Textareas within input fields */
.input-field textarea {
    width: 100%;
    padding: 8px;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    color: #276D8B;
    resize: vertical;
    min-height: 100px;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 5px;
    font-family: inherit;
    font-size: inherit;
}

.input-field textarea:focus {
    outline: none;
    box-shadow: 0 0 0 2px #1e5a73;
}

/* Expand button */
.expand-button {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #1e5a73;
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    z-index: 10;
}

.expand-button:hover {
    background-color: #154456;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .dropdown-container {
        flex-direction: column;
    }

    .dropdown-container > div {
        width: 100%;
        margin-bottom: 10px;
    }

    .title-card, .text-content {
        margin: 10px 0;
    }
}
