.activity-container-skew {
  background: linear-gradient(135deg, #99b2bd 0%, #8da9b4 60%, #6395aa 100%);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 30px;
  min-height: 600px;
  max-height: 80vh;
  color: #ffffff;
}

.main-content-skew {
  display: flex;
  flex-grow: 1;
  margin-bottom: 20px;
}

.bg-wrapper {
  background: rgba(0, 0, 0, 0.589);
  padding: 30px;
  border-radius: 10px;
}

.user-list-panel-skew {
  width: 30%;
  padding-right: 20px;
  overflow-y: auto;
}

.user-list-panel-skew h3 {
  margin-top: 0;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  padding: 10px 0;
}

.user-list-skew {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 500px;
}

.user-item-skew {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #00000067;
  border-radius: 5px;
  font-size: 0.9em;
}

.status-skew {
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 0.8em;
  text-transform: capitalize;
}

.status-skew.submitted { background-color: #FFA500; }
.status-skew.unsubmitted { background-color: #7070708a; }
.status-skew.accepted { background-color: #0dca13; }
.status-skew.rejected { background-color: #ff4133; }
.status-skew.pending { background-color: #707070; }

.skew-graph-panel-skew {
  width: 70%;
  padding-left: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.skew-graph-panel-skew h2 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.application-skew-container {
  background: rgba(0, 0, 0, 0.589);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
}

.timeline-container-skew {
  position: relative;
  height: 450px;
  width: 100%;
  max-width: 800px;
}

.timeline-skew {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 400px;
}

.timeline-line-skew {
  position: absolute;
  bottom: 200px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #3092e2;
}

.timeline-events-skew, .user-events-skew {
  position: absolute;
  left: 0;
  right: 0;
  height: 200px;
}

.timeline-events-skew {
  bottom: 200px;
}

.user-events-skew {
  top: 200px;
}

.timeline-event-skew {
  position: absolute;
  transform: translateX(-50%);
}

.event-line-skew {
  width: 2px;
  height: 200px;
  background-color: #3092e2;
}

.event-label-skew {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: #ffffff;
  font-size: 0.8rem;
}

.timeline-events-skew .event-label-skew {
  top: -20px;
}

.user-events-skew .event-label-skew {
  bottom: -20px;
}

.timeline-months-skew {
  position: absolute;
  bottom: 180px;
  left: 0;
  right: 0;
}

.month-label-skew {
  position: absolute;
  transform: translateX(-50%) rotate(-45deg);
  transform-origin: top left;
  color: #999999;
  font-size: 0.8rem;
  top: 5px;
  white-space: nowrap;
}

.application-dots-skew {
  position: absolute;
  top: 50px;
  bottom: 200px;
  left: 0;
  right: 0;
}

.application-dot-skew {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate(-50%, 50%);
}

.application-dot-skew.accepted { background-color: #0dca139a; }
.application-dot-skew.rejected { background-color: #ff4133a4; }
.application-dot-skew.pending { background-color: #7070708a; }
.application-dot-skew.submitted { background-color: #FFA500; }

.application-dot-skew:hover {
  background-color: #fff !important;
}

.bottom-panel-skew {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.firm-selector-skew select {
  background-color: #ffffff;
  color: #276D8B;
  border: 1px solid #276D8B;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%233092e2' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.firm-selector-skew select:hover,
.firm-selector-skew select:focus {
  background-color: #ffffff;
  color: #276D8B;
  border-color: #276D8B;
  outline: none;
}

.legend-skew {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.legend-item-skew {
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

.legend-color-skew {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.legend-color-skew.accepted { background-color: #0dca13; }
.legend-color-skew.rejected { background-color: #ff4133; }
.legend-color-skew.pending { background-color: #707070; }
.legend-color-skew.submitted { background-color: #FFA500; }

.hover-info-skew {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transform: translate(-50%, -100%);
  margin-bottom: 10px;
}

.hover-info-skew p {
  margin: 0;
  padding: 2px 0;
}

.loading-skew, .error-skew, .no-data-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #ffffff;
  font-size: 1.2rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
}

.error-skew {
  color: #F44336;
}

.custom-warning-banner {
  margin: 30px;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  padding: 20px 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 18px;
  text-align: center;
}

.custom-warning-banner .warning-icon {
  margin-right: 10px;
  color: #856404;
}

.custom-warning-banner .warning-text {
  flex-grow: 1;
}

.custom-warning-banner .warning-link {
  color: #0056b3;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  display: inline-flex;
  align-items: center;
}

.custom-warning-banner .warning-link:hover {
  color: #003d82;
}

.custom-warning-banner .copy-icon {
  margin-left: 5px;
}

.custom-warning-banner .copied-text {
  color: #276D8B;
  margin-left: 5px;
  font-weight: bold;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.custom-warning-banner .copied-text.fade-out {
  opacity: 0;
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.custom-warning-banner .copied-text {
  animation: fadeOut 2s ease-out 0.5s forwards;
}

@media (max-width: 1024px) {
  .main-content-skew {
    flex-direction: column;
  }

  .user-list-panel-skew, .skew-graph-panel-skew {
    width: 100%;
    padding: 0;
  }

  .user-list-panel-skew {
    margin-bottom: 20px;
    max-height: 200px;
  }

  .application-skew-container {
    height: 400px;
  }

  .timeline-container-skew {
    height: 350px;
  }

  .bottom-panel-skew {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .activity-container-skew {
    padding: 10px;
  }

  .application-skew-container {
    height: 300px;
  }

  .timeline-container-skew {
    height: 250px;
  }

  .event-label-skew, .month-label-skew {
    font-size: 0.7rem;
  }

  .legend-skew {
    flex-wrap: wrap;
  }
}