.success-container-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #E4E4E4;
    font-family: "Inter", sans-serif;
  }
  
  .loading-spinner-success, .error-message-success, .success-message-success {
    background-color: white;
    border-radius: 10px;
    padding: 40px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
  }
  
  .success-container-success h1, .success-container-success h2 {
    color: #276D8B;
    font-family: "Faustina", serif;
    margin-bottom: 20px;
  }
  
  .success-container-success p {
    color: #555;
    margin-bottom: 20px;
  }
  
  .spinner-success {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #276D8B;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin-success 1s linear infinite;
    margin: 0 auto 20px;
  }
  
  @keyframes spin-success {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .back-button-success {
    background-color: #276D8B;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .back-button-success:hover {
    background-color: #1c4d62;
  }
  
  .checkmark-container-success {
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
    position: relative;
  }
  
  .checkmark-success {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill-success .4s ease-in-out .4s forwards, scale-success .3s ease-in-out .9s both;
  }
  
  .checkmark_stem-success {
    transform-origin: 50% 50%;
    stroke-dasharray: 29;
    stroke-dashoffset: 29;
    animation: stroke-success 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  .checkmark_kick-success {
    transform-origin: 50% 50%;
    stroke-dasharray: 22;
    stroke-dashoffset: 22;
    animation: stroke-success 0.3s cubic-bezier(0.65, 0, 0.45, 1) 1.1s forwards;
  }
  
  @keyframes stroke-success {
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes scale-success {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  
  @keyframes fill-success {
    100% {
      box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
  }
  
  .error-message-success {
    border-left: 5px solid #d32f2f;
  }
  
  .error-message-success h2 {
    color: #d32f2f;
  }