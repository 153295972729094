/* Comparison.css */

.comparison-container {
    max-width: 100%;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    display: flex;  
    flex-direction: column;
    min-height: 95vh;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
}

h2 {
    color: #fff;
    font-size: 3rem;
    margin: 0;
}

.content {
    display: flex;
    position: relative;
    overflow: visible;
    flex-grow: 1;
}

.left-column, .right-column {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;
    flex-grow: 1;
}

.divider {
    width: 20px;
    background-color: transparent;
    cursor: col-resize;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.divider-line {
    width: 1px;
    background-color: #276D8B;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.divider-line.top {
    top: 20px;  /* Adds space at the top */
    height: calc(50% - 40px);  /* Adjusts height to maintain the space at both ends */
}

.divider-line.bottom {
    bottom: 20px;  /* Adds space at the bottom */
    height: calc(50% - 40px);  /* Adjusts height to maintain the space at both ends */
}



.divider-handle {
    width: 30px;
    height: 30px;
    background-color: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #276D8B;
    font-size: 16px;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button-container {
    display: flex;
    flex-direction: row;
    margin: 10px 30px;
    gap: 15px;
}

.submit-button2 {
    flex: 1;
    padding: 20px 15px;
    font-size: 0.9rem;
    background-color: #272221;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    width: calc(100% - 60px);
    align-self: center;
}

.submit-button2:hover {
    background-color: #3a3a3a;
}

.submit-button2:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}


.text-content {
    background-color: rgba(39, 109, 139, 0.6);
    padding: 25px;
    border-radius: 10px;
    color: #fff;
    overflow-y: auto;
    flex-grow: 1;
    margin: 10px 30px;
    display: flex;
    flex-direction: column;
}

.text-content textarea {
    width: 100%;
    height: 100%;
    min-height: 200px;
    border: none;
    resize: none;
    background-color: transparent;
    color: inherit;
    font: inherit;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
}

.text-content .bracketed-text {
    color: #FFD700; /* Gold color */
    font-style: italic;
}

.divider2 {
    width: 95%;
    height: 3px;
    display: none;
    background-color: rgba(0, 0, 0);
    margin: 20px 0;
    border-color: #111;
    align-self: center;
}

/* Responsive design */
@media (max-width: 768px) {
    .comparison-container {
        max-width: 100%;
        height: auto;
        overflow-y: visible;
        padding: 20px;
    }

    .content {
        flex-direction: column;
        overflow-y: visible;
    }
    
    .left-column, .right-column {
        width: 100% !important;
        height: auto;
        padding: 0px 0px;
    }

    .header {
        padding: 20px 20px;
        text-align: left;
    }

    h2 {
        font-size: 2rem; /* Reduced font size for mobile */
    }

    .text-content {
        margin: 10px 0px;
    }

    .divider {
        display: none;
    }

    .submit-button {
        margin: 10px 0px;
        padding: 10px 30px;
    }

    .button-container {
        margin: 00px 0px;
    }

    .divider2 {
        display: block;
    }
}