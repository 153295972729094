/* GenerateDraft.css */

/* General container styling */
.comparison-container-draft {
    max-width: 100%;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 95vh;
}

/* Main content layout */
.content-draft {
    display: flex;
    position: relative;
    overflow: visible;
    flex-grow: 1;
}

/* Left and right column layouts */
.left-column-draft, .right-column-draft {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;
    flex-grow: 1;
}

/* Divider for resizing columns */
.divider-draft {
    width: 20px;
    background-color: transparent;
    cursor: col-resize;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

/* Divider lines for visual separation */
.divider-line-draft {
    width: 1px;
    background-color: #276D8B;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.divider-line-draft.top {
    top: 20px;
    height: calc(50% - 40px);
}

.divider-line-draft.bottom {
    bottom: 20px;
    height: calc(50% - 40px);
}

/* Divider handle for dragging */
.divider-handle-draft {
    width: 30px;
    height: 30px;
    background-color: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #276D8B;
    font-size: 16px;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Button layout */
.button-container-draft {
    display: flex;
    flex-direction: row;
    margin: 10px 30px;
    gap: 15px;
}

/* Submit button styles */
.submit-button-draft {
    flex: 1;
    padding: 20px 15px;
    margin: 0;
    font-size: 0.9rem;
    background-color: #272221;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    align-self: center;
}

.submit-button-draft:hover {
    background-color: #3a3a3a;
}

.submit-button-draft:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Rich text editor styling */
.rich-text-editor-draft {
    background-color: rgba(39, 109, 139, 0.6);
    border-radius: 10px;
    margin: 10px 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

/* Toolbar inside the editor */
.editor-toolbar-draft {
    background-color: rgba(39, 109, 139, 0.8);
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.editor-toolbar-draft button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

.editor-toolbar-draft button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Draft.js editor content */
.editor-content-draft {
    flex-grow: 1;
    padding: 15px;
    color: white;
    font-size: 1rem;
    font-family: inherit;
    outline: none;
    overflow-y: auto;
    min-height: 300px;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    text-align: left;
}

.editor-content-draft .public-DraftEditor-content {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
    margin: 0;
}

/* Styling for formatted content */
.editor-content-draft b, .editor-content-draft strong {
    font-weight: bold;
}

.editor-content-draft i, .editor-content-draft em {
    font-style: italic;
}

.editor-content-draft u {
    text-decoration: underline;
}

.editor-content-draft ul, .editor-content-draft ol {
    padding-left: 30px;
    margin: 10px 0;
}

.editor-content-draft ul li, .editor-content-draft ol li {
    margin-bottom: 5px;
}

.editor-content-draft blockquote {
    border-left: 3px solid white;
    margin: 10px 0;
    padding-left: 10px;
    font-style: italic;
}

/* Word count display */
.word-count-draft {
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: white;
    font-size: 0.9rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 5px;
    pointer-events: none;
    z-index: 10;
}

.popup-draft {
    display: flex;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    justify-content: center;
    align-items: center;
  }
  
  .popup-content-draft {
    background-color: rgba(39, 109, 139, 0.8); /* Semi-transparent darker blue background */
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    width: 800px;
    position: relative;
    color: white;
  }
  
  .popup-content-draft h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.2em;
  }
  
  .close-btn-draft {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
  }
  
  .import-draft-textarea {
    width: 100%;
    height: 200px;
    margin: 10px 0;
    padding: 8px;
    border: none;
    background-color: rgba(255, 255, 255, 0.9); /* Slightly more opaque */
    color: #276D8B;
    resize: vertical;
    border-radius: 5px;
    font-family: inherit;
    font-size: inherit;
  }
  
  .import-draft-textarea:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(30, 90, 115, 0.5);
  }
  
  .import-draft-btn {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  .import-draft-btn:hover {
    background-color: rgba(30, 90, 115, 0.9);
  }

/* Popup animation */
.popup.show {
    display: flex;
    animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Responsive popup adjustments */
@media (max-width: 768px) {
    .popup-content {
        width: 90%;
        padding: 15px;
    }

    .close-btn {
        font-size: 1.2rem;
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .comparison-container-draft {
        max-width: 100%;
        height: auto;
        overflow-y: visible;
        padding: 20px;
    }

    .content-draft {
        flex-direction: column;
        overflow-y: visible;
    }

    .left-column-draft, .right-column-draft {
        width: 100% !important;
        height: auto;
        padding: 0;
    }

    .divider-draft {
        display: none;
    }

    .submit-button-draft {
        margin: 10px 0;
        padding: 10px 30px;
    }

    .button-container-draft {
        margin: 0;
    }

    .rich-text-editor-draft {
        margin: 10px 0;
    }
}
<<<<<<< HEAD

.word-count-draft {
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: #fff;
    font-size: 0.9rem;
    background-color: rgba(0, 0, 0, 0.5); /* Optional: background for better visibility */
    padding: 5px 10px;
    border-radius: 5px;
    pointer-events: none; /* So it doesn't interfere with text selection */
    z-index: 10;
}

.editor-container {
    position: relative; /* Ensure the word count is positioned relative to the editor */
    flex-grow: 1;
}

.save-toolbar {
    background-color: #276D8B;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0;
    transition: 1s;
  }
  
  .save-toolbar input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #4A90B6;
  }
  
  .save-toolbar input::placeholder {
    color: #4A90B6;
    opacity: 0.7;
  }
  
  .save-toolbar button {
    background-color: #444444;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .save-toolbar button:hover {
    background-color: #333333;
  }
  
  .comparison-container-draft {
    display: flex;
    flex-direction: column;
  }
  
  .content-draft {
    display: flex;
    flex-grow: 1;
  }

  .draft-title {
    color: #fff;
    font-weight: 600;
    padding-left: 50px;
    font-size: 24px;
  }
  
  /* Ensure the toolbar doesn't have rounded corners at the top */
  .comparison-container-draft .save-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .upgrade-bar {
    background-color: #b41414;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .upgrade-button {
    background-color: #276D8B;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .upgrade-button:hover {
    background-color: #1c5a6f;
  }
  
  .submit-button-draft:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

.firm-selection-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.import-button {
  background-color: #276D8B;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.import-button:hover {
  background-color: #1c5a6f;
}

.import-button svg {
  margin-right: 5px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .firm-selection-container {
    flex-direction: column;
    align-items: stretch;
  }

  .import-button {
    margin-left: 0;
    margin-top: 10px;
  }
}

.import-editor-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.import-editor-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.close-import-editor {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.import-editor {
  border: 1px solid #ccc;
  min-height: 200px;
  padding: 10px;
  margin-bottom: 10px;
  overflow-y: auto;
}

.import-editor .DraftEditor-root {
  height: 100%;
  min-height: 180px;
}

.import-editor .public-DraftEditorPlaceholder-root {
  color: #999;
}

.apply-import {
  background-color: #276D8B;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.apply-import:hover {
  background-color: #1c5a6f;
}