.score-warnings-container {
    margin-bottom: 20px;
  }
  
  .warning-box-warning, .success-box-warning {
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 10px;
    display: flex;
    align-items: start;
  }
  
  .warning-box-warning {
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
  }
  
  .success-box-warning {
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
  }
  
  .warning-icon-warning, .success-icon-warning {
    margin-right: 10px;
    flex-shrink: 0;
  }
  
  .warning-icon-warning {
    color: #856404;
  }
  
  .success-icon-warning {
    color: #155724;
  }
  
  .warning-text-warning, .success-text-warning {
    margin: 0;
    font-size: 14px;
  }
  
  .warning-text-warning {
    color: #856404;
  }
  
  .success-text-warning {
    color: #155724;
  }