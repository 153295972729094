@import url('https://fonts.googleapis.com/css2?family=Inika:wght@400;700&family=Inter:wght@100..900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inika:wght@400;700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Faustina:ital,wght@0,300..800;1,300..800&display=swap');



* {
  font-family: "Inter", sans-serif;
}

.layout {
  display: flex;
  min-height: 100vh;
  background-color: #E4E4E4;
}

.sidebar {
  max-width: 250px;
  min-width: 250px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: sticky;
  top: 0;
  padding: 20px;
  box-sizing: border-box;
}

.sidebar-content {
  flex-grow: 1;
  overflow-y: auto;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #276D8B;
  margin-bottom: 20px;
  font-family: "Faustina", serif;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav li {
  padding: 10px 0;
  cursor: pointer;
  text-decoration: none;
}

nav li:hover {
  color: #276D8B;
}

.section-title {
  font-weight: bold;
  color: #888;
  margin-top: 15px;
  text-decoration: none;
}

.section-title:hover {
  color: #888 !important;
  cursor: default;
}

.seperator {
  height: 2px;
  background-color: #e0e0e0;
  margin: 10px 0;
}

.seperator2 {
  height: 2px;
  background-color: #9c9c9c;
  margin: 10px 0;
}

.active {
  color: #276D8B;
  font-weight: bold;
  text-decoration-color: #276D8B;
}

.user-info {
  margin-top: 20px;
}

.user-name {
  color: #276D8B;
  font-weight: 700;
  font-size: 18px;
}

.user-type {
  font-size: 0.9em;
  color: #888;
  margin-bottom: 10px;
}

.credits {
  font-weight: 700;
  font-size: 24px;
  color: #111;
}

.get-credits-btn {
  background-color: #276D8B;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.get-credits-btn:hover {
  background-color: #1c4d62;
}

.main-content {
  flex-grow: 1;
  position: relative;
}

.menu-toggle {
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.logout-btn {
  background-color: #276D8B;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

.logout-btn:hover {
  background-color: #d32f2f;
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    left: -250px;
    top: 0;
    bottom: 0;
    transition: left 0.3s ease;
    z-index: 1000;
  }

  .sidebar.open {
    left: 0;
  }

  .menu-toggle {
    display: block;
  }

  .main-content {
    padding-top: 50px;
  }

  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

.join-society {
  color: #888;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 5px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
}

.popup-content h2 {
  margin-top: 0;
}

.join-button, .close-button {
  margin-top: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.join-button {
  background-color: #276D8B;
  color: white;
  border: none;
}

.close-button {
  background-color: #888;
  color: white;
  border: none;
  margin-left: 10px;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.dropdown-content {
  list-style-type: none;
  padding-left: 20px;
  margin-top: 5px;
}

.saved-draft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.saved-draft a {
  flex-grow: 1;
  color: inherit;
  text-decoration: none;
}

.saved-draft.active {
  background-color: #276D8B !important;
  color: white !important;
}

.saved-draft:hover {
  background-color: #e6f3f7;
}

.add-new {
  font-weight: 800;
}

.delete-draft-btn {
  background: none;
  border: none;
  color: darkred;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.delete-draft-btn:hover {
  color: red;
}

.delete-draft-btn svg {
  width: 14px;
  height: 14px;
}

.frosted-glass-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.frosted-glass-popup .popup-content {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  text-align: center;
  max-width: 500px;
  width: 90%;
}

.frosted-glass-popup h2 {
  color: #276D8B;
  margin-bottom: 20px;
}

.firm-countdown-bars {
  margin-bottom: 20px;
}

.firm-countdown-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.firm-name {
  flex: 1;
  text-align: left;
  font-weight: bold;
}

.progress-bar {
  flex: 2;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 10px;
}

.progress {
  height: 100%;
  background-color: #276D8B;
  transition: width 0.3s ease;
}

.status {
  flex: 0 0 100px;
  text-align: right;
  font-size: 0.9em;
  color: #276D8B;
}

.close-button {
  background-color: #276D8B;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #1c4d62;
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.option input[type="radio"] {
  display: none;
}

.option label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.option label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #276D8B;
  border-radius: 50%;
}

.option input[type="radio"]:checked + label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: #276D8B;
  border-radius: 50%;
}

.option.selected {
  background-color: #276D8B;
}

.option.selected label {
  color: white;
}

.option.selected label:before {
  border-color: white;
}

.option.selected input[type="radio"]:checked + label:after {
  background-color: white;
}

.other-input {
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
}

.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #276D8B;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay > div {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}
.label-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.new-tag {
  background-color: #ff4757;
  color: white;
  font-size: 0.7em;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: top;
}

.no-firms-prompt {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404 !important;
  padding: 15px;
  margin: 15px 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: center;
}

.no-firms-prompt::before {
  margin-right: 10px;
  font-size: 18px;
}

.work-experience-page-container {
  padding: 24px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}